/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['locationsCheckboxes', 'form', 'checkboxErrors', 'selectedLocations', 'companyCheckbox'];

  connect() {
    $(document).click((e) => {
      if (e.target.id !== 'overSelect' && !$('#locationsCheckboxes').find(e.target).length) {
        $('#locationsCheckboxes').removeClass('active');
      }
    });
  }

  toggleLocation() {
    const locations = this.companyCheckboxTargets.filter((e) => e.checked).map((e) => e.dataset.employer).join('<br/> ');
    const html = `<strong>Selected Locations:</strong> <br/>${locations}`;
    this.selectedLocationsTarget.innerHTML = html;
  }

  showCheckboxes() {
    this.locationsCheckboxesTarget.classList.toggle('active');
  }

  submitForm(event) {
    const isValid = this.validateForm(this.formTarget);

    // If our form is invalid, prevent default on the event
    if (!isValid) {
      event.preventDefault();
    }
  }

  validateForm() {
    let isValid = true;

    // Find required fields
    const requiredFieldSelectors = 'input:required, select:required';
    const requiredFields = this.formTarget.querySelectorAll(requiredFieldSelectors);

    const textareaOtherInfoValue = document.getElementById('employer_other_info').value;

    // If at least one checkbox is checked (value = 1) - form is valid
    if ($('#individualsInfo').find(':checked').length === 0 && textareaOtherInfoValue === '') {
      isValid = false;
      this.checkboxErrorsTarget.classList.remove('hidden');
    } else {
      isValid = true;
      this.checkboxErrorsTarget.classList.add('hidden');
    }

    requiredFields.forEach((field) => {
      field.classList.remove('invalid');
      field.placeholder = '';

      // For each required field, check to see if the value is empty
      if (!field.disabled && !field.value.trim()) {
        field.classList.add('invalid');

        field.placeholder = 'Please fill this out!';
        isValid = false;
      }
    });

    return isValid;
  }

  validateInputField(event) {
    // Find the error message element
    const errorMessage = event.target.parentNode.getElementsByClassName('employer-error-message')[0];
    const targetName = event.target.name;

    // Display error message if value of the input is empty
    if (event.target.value === '') {
      event.target.style.marginBottom = 0;
      errorMessage.classList.remove('hidden');

      // Match the error messages with the appropriate input fields base od input name
      if (targetName === 'employer[first_name]' || targetName === 'employer[backup_first_name]') {
        errorMessage.innerText = 'First name is required!';
      } else if (targetName === 'employer[last_name]' || targetName === 'employer[backup_last_name]') {
        errorMessage.innerText = 'Last name is required!';
      } else if (targetName === 'employer[position]' || targetName === 'employer[backup_position]') {
        errorMessage.innerText = 'Position/title is required!';
      } else if (targetName === 'employer[address_1]' || targetName === 'employer[backup_address_1]') {
        errorMessage.innerText = 'Address is required!';
      } else if (targetName === 'employer[address_2]' || targetName === 'employer[backup_address_2]') {
        errorMessage.innerText = 'Unit number/address 2 is required!';
      } else if (targetName === 'employer[city]' || targetName === 'employer[backup_city]') {
        errorMessage.innerText = 'City is required!';
      } else if (targetName === 'employer[state]' || targetName === 'employer[backup_state]') {
        errorMessage.innerText = 'State is required!';
      } else if (targetName === 'employer[zip_code]' || targetName === 'employer[backup_zip_code]') {
        errorMessage.innerText = 'Zip Code is required!';
      } else if (targetName === 'employer[office_phone]') {
        errorMessage.innerText = 'Office phone is required!';
      } else if (targetName === 'employer[mobile_phone]') {
        errorMessage.innerText = 'Mobile phone is required!';
      } else if (targetName === 'employer[email]' || targetName === 'employer[backup_email]') {
        errorMessage.innerText = 'Email address is required!';
      } else if (targetName === 'employer[total_employees]') {
        errorMessage.innerText = 'Total number of employees is required!';
      } else if (targetName === 'employer[total_locations]') {
        errorMessage.innerText = 'Total number of locations is required!';
      } else {
        event.target.classList.remove('invalid');
        errorMessage.classList.add('hidden');
      }
      // else - Validate the user input when input value is not empty
    } else if (targetName === 'employer[zip_code]' || targetName === 'employer[backup_zip_code]') {
      // Validate zip code input field
      const zipCodeRe = /^\d{5}(-\d{4})?$/;
      // Display error message if user's input is not valid
      if (!zipCodeRe.test(event.target.value)) {
        event.target.style.marginBottom = 0;
        errorMessage.classList.remove('hidden');
        errorMessage.innerText = 'Incorrect zip code format!';
      } else {
        // Remove error message when the user's input is valid
        event.target.classList.remove('invalid');
        event.target.style.marginBottom = '16px';
        errorMessage.innerText = '';
        errorMessage.classList.add('hidden');
      }
    } else if (targetName === 'employer[office_phone]' || targetName === 'employer[mobile_phone]') {
      // Validate phone number input field
      const phoneRe = /^\(?[\d]{3}\)?[\s-]?[\d]{3}[\s-]?[\d]{4}$/;
      // Display error message if user's input is not valid
      if (!phoneRe.test(event.target.value)) {
        event.target.style.marginBottom = 0;
        errorMessage.classList.remove('hidden');
        errorMessage.innerText = 'Incorrect phone format!';
      } else {
        // Remove error message when the user's input is valid
        event.target.classList.remove('invalid');
        event.target.style.marginBottom = '16px';
        errorMessage.innerText = '';
        errorMessage.classList.add('hidden');
      }
    } else if (targetName === 'employer[email]' || targetName === 'employer[backup_email]') {
      // Validate email input field
      const emailRe = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      // Display error message if user's input is not valid
      if (!emailRe.test(event.target.value)) {
        event.target.style.marginBottom = 0;
        errorMessage.classList.remove('hidden');
        errorMessage.innerText = 'Incorrect email format!';
      } else {
        // Remove error message when the user's input is valid
        event.target.classList.remove('invalid');
        event.target.style.marginBottom = '16px';
        errorMessage.innerText = '';
        errorMessage.classList.add('hidden');
      }
    } else if (targetName === 'employer[total_employees]' || targetName === 'employer[total_locations]') {
      // Validate number of employees and locations
      const numberRe = /^[1-9]+[0-9]*$/;
      // Display error message if user's input is not valid
      if (!numberRe.test(event.target.value)) {
        event.target.style.marginBottom = 0;
        errorMessage.classList.remove('hidden');
        errorMessage.innerText = 'Please enter a number!';
      } else {
        // Remove error message when the user's input is valid
        event.target.classList.remove('invalid');
        event.target.style.marginBottom = '16px';
        errorMessage.innerText = '';
        errorMessage.classList.add('hidden');
      }
    } else {
      // Remove error message when the input is valid
      event.target.classList.remove('invalid');
      event.target.style.marginBottom = '16px';
      errorMessage.innerText = '';
      errorMessage.classList.add('hidden');
    }
  }
}
